.App {
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

img {
    max-width: 100%;
    max-height: 100%;
}

body {
    overflow: hidden
}
